import React from "react"
import { Link } from "gatsby"

class Button extends React.Component {
    render() {

        // Classes
        let base = "basis inline-flex items-center px-5 py-2 border border-transparent text-base leading-6 font-medium rounded focus:outline-none transition ease-in-out duration-150 "
        let spanClasses = "inline-flex rounded-md shadow-sm"
        let color = "text-white bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700"
        if (this.props.color === "red") {
            color = "text-white bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red active:bg-red-700"
        }
        if (this.props.color === "white") {
            color = "text-white bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700"
        }
        if (this.props.color === "dark-gray") {
            color = "text-gray-300 bg-gray-700 hover:bg-gray-600 focus:border-gray-600 focus:shadow-outline-gray active:bg-gray-600"
        }
        if (this.props.color === "gray") {
            color = "text-white bg-gray-500 hover:bg-gray-400 focus:border-gray-600 focus:shadow-outline-gray active:bg-gray-600"
        }
        if (this.props.color === "light-gray") {
            color = "text-gray-700 bg-gray-200 hover:bg-gray-300 focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-300"
        }
        let classes = base + color

        if (this.props.dashboard) {
            classes = "dashboard-button basis px-3 py-1 text-sm sm:px-5 sm:py-2 sm:text-base leading-6 text-center inline-block items-center border border-transparent font-medium rounded focus:outline-none transition ease-in-out duration-150 text-white bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700"
        }


        // Link Style
        if (this.props.linkstyle) {
            classes = "system text-lg text-blue-700 underline"
            spanClasses = "inline-flex"
        }

        // Styles
        let styles
        if (this.props.medium) {
            styles = { minWidth: "214px", textAlign: "center", display: "inline-block" }
        }
        if (this.props.small) {
            styles = { minWidth: "60px", textAlign: "center", display: "inline-block" }
        }

        // Icons
        let icon
        if (this.props.icon === "plus") {
            icon =
                <svg style={{ top: "1px" }} className="relative w-4 h-4 inline mr-2 -mt-1" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4V20M20 12L4 12" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round" />
                </svg>
            if (this.props.dashboard) {
                icon =
                    <svg style={{ top: "1px" }} className="relative w-3 h-3 sm:w-4 sm:h-4 inline mr-2 -mt-1.5 sm:-mt-1" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4V20M20 12L4 12" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round" />
                    </svg>
            }
        }
        if (this.props.icon === "user") {
            icon =
                <svg style={{ top: "3px" }} className="relative w-4 h-4 inline mr-2 -mt-1" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 9C11.6569 9 13 7.65685 13 6C13 4.34315 11.6569 3 10 3C8.34315 3 7 4.34315 7 6C7 7.65685 8.34315 9 10 9ZM3 18C3 14.134 6.13401 11 10 11C13.866 11 17 14.134 17 18H3Z" fill="currentColor" />
                </svg>
        }
        if (this.props.icon === "check") {
            icon =
                <svg style={{ top: "2px" }} className="relative w-5 h-5 inline mr-2 -mt-1" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13L9 17L19 7" />
                </svg>
        }
        if (this.props.icon === "back") {
            icon =
                <svg style={{ top: "2px" }} className="relative w-4 h-4 inline mr-2 -mt-1" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 19L3 12M3 12L10 5M3 12L21 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
        }

        // Link or Button
        let button
        if (this.props.link) {
            button =
                <Link to={this.props.to} style={styles} className={classes}>
                    {icon}
                    <span className="down-1px">{this.props.children}</span>
                </Link>
        }
        else if (this.props.external) {
            button =
                <a href={this.props.href} style={styles} className={classes}>
                    {icon}
                    <span className="down-1px">{this.props.children}</span>
                </a>
        }
        else {
            button =
                <button onClick={this.props.onClick} style={styles} type="button" className={classes}>
                    {icon}
                    <span className="down-1px">{this.props.children}</span>
                </button>
        }

        return (
            <span className={spanClasses}>
                {button}
            </span>
        )
    }
}

export default Button